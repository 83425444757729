@import "../../styles/variables.scss";
@import "../../styles/functions.scss";
@import "../../styles/fonts.scss";

.target-wrapper {
  margin-top: 2.5%;
  margin-bottom: 120px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 26px;
  opacity: 1;

  .target-header {
    display: flex;
    justify-content: space-between;
    background: #fafafa 0% 0% no-repeat padding-box;
    border-radius: 26px 26px 0px 0px;
    opacity: 1;
    height: 70px;
    align-items: center;
  }

  .target-title {
    font-family: "Open Sans Semibold" !important;
    letter-spacing: 0px;
    font-size: 20px;
    color: $pure_black;
    opacity: 1;
  }
  .info-custom {
    background-color: #2d7c43;
    color: white;
    padding: 5px;
    right: 36px;
    top: 10px;
    z-index: 2;
    border-radius: 5px;
    display: flex;
    align-items: center;
    .info {
      font-family: "Open Sans Semibold";
      font-size: 12px;
    }
    .close-icon {
      margin-left: 6px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  .summary-wrapper {
    background: icon("icon-illustration", "svg");
    display: flex;
    flex-direction: column;
    // margin-left: 20px;
    // margin-right: 12px;
    // margin-top: 20px;

    // height: 122px;
    /* UI Properties */
    // background: #fafdff 0% 0% no-repeat padding-box;

    opacity: 1;
    // padding-left: 20px;
    // margin-bottom: 20px;
    position: relative;
    padding: 20px 12px 20px 20px;

    .summary-padding {
      // height: 122px;
      border: 2px dashed #bbe1f5;
      border-radius: 4px;
      padding-left: 20px;
      background: #fafdff 0% 0% no-repeat padding-box;
      // overflow-y: auto;
    }
    .summary-title {
      text-align: left;
      font-family: "Open Sans Semibold";
      font-size: 16px;
      // font-weight: 600;
      letter-spacing: 0px;
      color: $pure_black;
      opacity: 1;
      padding-top: 20px;
    }
    .summary-desc {
      font-size: 14px;
      letter-spacing: 0px;
      color: $pure_black;
      opacity: 1;
      display: flex;
      align-items: center;
      gap: 6px;
      flex-wrap: wrap;
      height: 15px;
      // overflow: scroll;
      overflow-y: auto;
      justify-content: flex-start;
      padding-bottom: 20px;
      padding-top: 25px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .summary-count-hcps-wrapper {
      background: #deedf7 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 1;
      width: 347px;
      height: 61px;
      display: flex;
      align-items: center;
      justify-content: center;
      .summary-count-number {
        font-family: Open Sans Semibold;
        // font-weight: 600;
        font-size: 18px;
        letter-spacing: 0px;
        color: #0f6f7c;
        opacity: 1;
      }
    }
  }

  .query-text {
    text-align: left;
    font-family: Open Sans Regular;
    font-size: 14px;
    letter-spacing: 0px;
    color: $pure_black;
    font-size: 14px;
    opacity: 1;
    padding-bottom: 32px;
  }
  .mandatory-missing {
    color: $col-notification;
    font-size: 14px;
    line-height: 14px;
    padding-top: 6px;
  }

  .preview-wrapper-button {
    width: 51px;
    height: 314px;
    /* UI Properties */
    background: #b9e3fd 0% 0% no-repeat padding-box;
    box-shadow: -6px 3px 6px #0000001d;
    opacity: 1;
    border-radius: 8px 0px 0px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .preview-text {
      font-family: Open Sans Semibold;
      text-transform: uppercase;
      //  font-weight: 600;
      font-size: 18px;
      letter-spacing: 18px;
      color: $pure_black;
      opacity: 1;
      text-orientation: upright;
      writing-mode: vertical-rl;
      margin-top: 20px;
    }
  }

  .save-popup {
    margin: 17px 89px 40px 90px;
    max-width: 490px;
  }
  .target-name,
  .target_type {
    color: #767474;
    font-size: 16px;
    line-height: 22px;
    margin-left: 3px;
    margin-bottom: 12px;
  }
  .target-replace-info{
    margin-top: 5px; 
    color: #149ccc;
    font-size: 14px;
  }
  .input-target-name {
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dfe2e5;
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;
    line-height: 19px;
    &:active,
    &:hover {
      border: 1px solid #149ccc;
    }
    &:focus {
      border: 1px solid #149ccc;
    }
    // &:hover {
    //   border: 1px solid #149ccc;
    // }
  }
  .btn-apply {
    padding-right: 24px;
    padding-bottom: 24px;
    grid-column-gap: 20px;
  }
  .target-success-icon {
    height: 80px;
    width: 80px;
    margin-left: 300px;
    margin-bottom: 50px;
  }
  .save-success-popup {
    padding-top: 43px;
    padding-bottom: 24px;
  }

  .btn-gap {
    grid-column-gap: 25px;
    margin-left: 158px;
    width: unset;
  }

  .popup-wrapper {
    max-height: 97%;
  }
  .targeting-left-area {
    height: calc(100vh - 220px);
    overflow: auto;
    min-width: 325px;
  }
  .targeting-right-area {
    height: calc(100vh - 220px);
    overflow: auto;
  }
  .iframe-area {
    height: calc(100vh - 150px);
  }
  .user-file-popup {
    width: 50% !important;
    // .sending-info{
    //   margin-top: 60px;
    // }
    //   .target-submit-not-uploaded {
    //     margin: 12px 24px 24px auto;
    // }
  }
  .save-user-upload-popup {
    max-width: 671px;
    .save-success-popup {
      display: flex;
      align-items: center;
    }
    .target-success-icon {
      margin-left: 0;
      margin-bottom: 30px;
    }
    .success_msg {
      font-family: Open Sans Semibold;
      font-size: 24px;
      line-height: 1.37;
      color: #767474;
      // font-weight: 600;
    }
  }

  .summary-wrapper-fixed {
    
    display: flex;
    flex-direction: column;  

    opacity: 1;
   
    padding: 20px 12px 20px 20px;
    position: fixed;
    top: 42%;
    width: calc(100vw - 425px);

    .summary-padding-fixed {
     
      border: 1px dashed #bbe1f5;
      border-radius: 4px;
      padding-left: 20px;
      background: #fafdff 0% 0% no-repeat padding-box;
      background: #F8FCFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 5px #00000031;
      opacity: 1;
      .summary-heading-wrapper{
        display: flex;
      }
    }
    .summary-title-fixed {
      text-align: left;
      font-family: "Open Sans Semibold";
      font-size: 16px;
      letter-spacing: 0px;
      color: $pure_black;
      opacity: 1;
      padding-top: 12px;
    }
    .summary-desc-fixed {
      font-size: 14px;
      letter-spacing: 0px;
      color: $pure_black;
      opacity: 1;
      display: flex;
      align-items: center;
      gap: 6px;
      flex-wrap: wrap;
      height: 15px;
      overflow-y: auto;
      justify-content: flex-start;
      padding-bottom: 20px;
      // padding-top: 25px;
      margin-top: 5px;
      margin-bottom: 10px;
    }
    .close-icon{
      height: 24px;
      width: 24px;
      margin-left: auto;
      margin-bottom: auto;
      cursor: pointer;
    }
  
  }
}
